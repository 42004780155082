body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,"Figtree" 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  height: 34px;
  padding: 0;
  width: 30px;
}

#style2::-webkit-color-swatch {
  border-radius: 50%;
  border: 1px solid #cbd5e1;
}
/* #style2::-moz-color-swatch {
  border-radius: 50%;
  border: 7px solid #000000;
} */
